var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'product-faqs',
    _vm.removeContainerClass ? 'product-faqs__reduced-padding' : 'container' ],attrs:{"id":"questions","role":"region","aria-labelledby":"faq-heading"}},[_c('client-only',[(_vm.title)?_c('h4',{class:['ln-section-title', _vm.expandAll ? 'tw-text-left' : ''],attrs:{"id":"faq-heading"}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{class:_vm.title ? 'tw-mt-10' : '',attrs:{"role":"presentation"}},_vm._l((_vm.data.faq),function(item,index){return _c('div',{key:'prod-faq' + index,class:[
          'accordion',
          _vm.active === index ? 'accordion-active' : '',
          _vm.expandAll ? 'accordion-active' : '',
          'accordion' + index,
          _vm.whiteBg ? 'tw-bg-white' : 'tw-bg-light-grey' ],attrs:{"role":"group"}},[_c('div',{staticClass:"accordion__header tw-flex tw-items-center tw-justify-between",attrs:{"role":"button","aria-expanded":"true","aria-controls":'accordion-panel-' + index,"id":'accordion-header-' + index},on:{"click":function($event){return _vm.setActive(index)}}},[_c('p',{class:['tw-font-medium headline-5', _vm.expandAll ? 'tw-mt-4' : ''],domProps:{"innerHTML":_vm._s(item.table.question)}}),_vm._v(" "),(!_vm.expandAll)?_c('div',{staticClass:"accordion__arrow-down"},[_c('Chevron')],1):_vm._e()]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandAll ? _vm.expandAll : _vm.active === index),expression:"expandAll ? expandAll : active === index"}],staticClass:"accordion__body",attrs:{"id":'accordion-' + index,"accordion":"my-accordion","role":"region","aria-labelledby":'accordion-header-' + index}},[_c('p',{staticClass:"body-m tw-pb-6",domProps:{"innerHTML":_vm._s(item.table.answer)}})])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }