//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      active: 0,
    };
  },
  props: {
    data: {
      required: true,
    },
    title: {
      required: false,
      default: "FAQs",
    },
    removeContainerClass: {
      required: false,
      default: false,
    },
    expandAll: {
      required: false,
      default: false,
    },
    whiteBg: {
      required: false,
      default: false,
    },
  },
  methods: {
    setActive(index) {
      if (index === this.active) this.active = null;
      else this.active = index;
    },
  },
};
